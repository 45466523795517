import styled from 'styled-components'

import theme from '../theme'

const SearchLink = styled.div`
  font-size: 1em;
  color: ${theme.color.link};
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
`

export default SearchLink
